.str-chat {
  --str-chat__surface-color: #f4f4f4;
  --str-chat__secondary-surface-color: #f4f4f4;
  --str-chat__primary-surface-color: #ff811a;
  --str-chat__primary-surface-color-low-emphasis: #edf7f7;
  --str-chat__quoted-message-max-width: calc(var(--str-chat__spacing-px) * 480);
  --str-chat__message-with-attachment-max-width: calc(var(--str-chat__spacing-px) * 480);
  --str-chat__message-reaction-background-color: white;
  --str-chat__own-message-reaction-background-color: white;
  --str-chat__font-family: var(--str-root__font-family, initial);
  --str-chat__container-width: 100%;

  --system-component-onSurface: #0d0d0d;
  --system-component-onSurfaceHigh: #737373;
  --reference-fontFamily-primary: 'Hiragino Sans';
  --system-component-surface: #f1f1f1;

  --reference-fontSize-sm: 12px;
  --reference-fontSize-md: 14px;

  --onPrimary: #fff;
  --impression-onPrimary: #fff;
  --impression-primaryContainer: #ff9a47;
  --bubblePrimary: linear-gradient(268deg, #ff9a47 0.69%, #ff811a 100%);
  --impression-primary: #ff811a;
  --component-outline: #d9d9d9;
  --component-onSurface: #0d0d0d;
  --component-onSurfaceHighest: #bfbfbf;

  --system-spacing-3xs: 2px;
  --system-spacing-2xs: 4px;
  --system-spacing-xs: 8px;
  --system-spacing-md: 16px;

  --system-borderRadius-full: 9999px;
  --system-borderRadius-small: 4px;
  flex: 1;
}

.str-chat__message-input .str-chat__message-textarea-react-host {
  word-break: break-all;
}

.str-chat__parent-message-li {
  max-width: var(--str-chat__container-width);
  margin-right: max((100% - var(--str-chat__container-width)) / 2, 0px) !important;
  margin-left: max((100% - var(--str-chat__container-width)) / 2, 0px) !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
  border-block-end-color: #d9d9d9 !important;
}
.str-chat__li {
  max-width: var(--str-chat__container-width);
  margin-right: max((100% - var(--str-chat__container-width)) / 2, 8px) !important;
  margin-left: max((100% - var(--str-chat__container-width)) / 2, 8px) !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 1.1em;
}

.str-chat__date-separator {
  padding-right: max((100% - var(--str-chat__container-width)) / 2, 20px) !important;
  padding-left: max((100% - var(--str-chat__container-width)) / 2, 20px) !important;
}

/* Message Input Text */
.ProseMirror ol {
  list-style-type: decimal;
}

.ProseMirror ol ol {
  list-style-type: lower-alpha;
}

.ProseMirror ol ol ol {
  list-style-type: lower-roman;
}

.ProseMirror ul {
  list-style-type: disc;
}

.ProseMirror ul ul {
  list-style-type: circle;
}

.ProseMirror ul ul ul {
  list-style-type: square;
}

/* Message Text */
.str-chat__message--me .str-chat__message-text-inner > div {
  display: flex;
  flex-direction: column;
  gap: var(--system-spacing-xs, 8px);
}

.str-chat__message--other .str-chat__message-text-inner > div {
  display: flex;
  flex-direction: column;
  gap: var(--system-spacing-md, 8px);
}

.str-chat__message-text-inner br {
  display: none;
}

.str-chat__message-text-inner ol {
  list-style-type: decimal;
}

.str-chat__message-text-inner ol ol {
  list-style-type: lower-alpha;
}

.str-chat__message-text-inner ol ol ol {
  list-style-type: lower-roman;
}

.str-chat__message-text-inner ul {
  list-style-type: disc;
}

.str-chat__message-text-inner ul ul {
  list-style-type: circle;
}

.str-chat__message-text-inner ul ul ul {
  list-style-type: square;
}

.str-chat__message-text-inner p,
ul,
ol {
  line-height: 1.4em;
  user-select: text;
  -webkit-user-select: text;
}

.str-chat__message-text-inner ol,
ul {
  padding-inline-start: 20px;
}

.str-chat__message-text-inner p + p {
  padding-top: 1em;
}

.str-chat__message--other p strong {
  color: var(--impression-primary, #ff811a);
  font-size: var(--reference-fontSize-md, 14px);
  font-style: normal;
  font-weight: 400;
}

.str-chat__message--other .str-chat__message-text-inner p a {
  color: var(--component-onSurface, #0d0d0d);
  font-size: var(--reference-fontSize-md, 14px);
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
}

.str-chat__message--other .str-chat__message-text-inner blockquote {
  margin: 0;
  border-left: 3px solid var(--component-onSurfaceHighest);
  border-radius: 1px;
  padding: 0 0 0 0.5rem;
}

.str-chat__message--other .str-chat__message-text-inner pre {
  border-radius: var(--system-borderRadius-small, 4px);
  border: 1px solid var(--component-outline, #d9d9d9);
  background: var(--component-surfaceHigh, #f6f6f6);
  display: flex;
  margin: 0;
  padding: var(--system-spacing-3xs, 2px) var(--system-spacing-2xs, 4px);
  align-items: flex-start;
  gap: 10px;
}

.str-chat__message-text-inner p code {
  border-radius: var(--system-borderRadius-small, 4px);
  border: 1px solid var(--component-outline, #d9d9d9);
  font-size: var(--reference-fontSize-md, 14px);
  font-style: normal;
  font-weight: 700;
  display: flex;
  margin: 0;
  padding: var(--system-spacing-3xs, 2px) var(--system-spacing-2xs, 4px);
  align-items: flex-start;
  gap: 10px;
  width: fit-content;
}

.str-chat__message--other .str-chat__message-text-inner p code {
  color: var(--impression-primary);
}

.str-chat__message--me .str-chat__message-text-inner p code {
  background: var(--impression-primaryContainer);
  color: var(--impression-onPrimary);
}

.str-chat__message-text-inner code {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 700;
  text-wrap: wrap;
  font-size: var(--reference-fontSize-md, 14px);
}

.str-chat__message--me .str-chat__message-text-inner p a {
  color: var(--impression-onPrimary, #fff);
  font-size: var(--reference-fontSize-md, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-decoration-line: underline;
}

.str-chat__message--me .str-chat__message-text-inner blockquote {
  margin: 0;
  border-left: 3px solid var(--impression-onPrimary);
  border-radius: 1px;
  padding: 0 0 0 0.5rem;
}

.str-chat__message--me .str-chat__message-text-inner pre {
  border-radius: var(--system-borderRadius-small, 4px);
  border: 1px solid var(--impression-onPrimary, #fff);
  background: var(--impression-primaryContainer, #ff9a47);
  display: flex;
  margin: 0;
  padding: var(--system-spacing-3xs, 2px) var(--system-spacing-2xs, 4px);
  align-items: flex-start;
  gap: 10px;
}

.str-chat__message--me .str-chat__message-text-inner rt {
  user-select: none;
  -webkit-user-select: none;
}

.str-chat__modal--open {
  width: 100vw;
}

/* https://github.com/u-motion/u-motion-apps/issues/1660 */
/* https://github.com/u-motion/u-motion-apps/issues/1876 */
.str-chat__li > div {
  z-index: unset;
}

.str-chat__channel-preview-messenger--last-message_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.str-chat__channel-preview-messenger--name span {
  font-size: 14px;
  font-weight: 700;
  color: #0d0d0d;
}

.str-chat__message.str-chat__message--other {
  grid-template-areas:
    'avatar metadata'
    '. message'
    '. replies'
    '. translation-notice';
  .str-chat__message-inner {
    grid-template-areas:
      'message-bubble options'
      'reactions reactions';
  }
  .str-chat__message-bubble {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    max-width: 450px;
  }
}

/* メッセージホバー時にメッセージがガタつくのを防止 */
.str-chat__message--other .str-chat__message-inner {
  margin-right: 0;
}
.str-chat__message--me .str-chat__message-inner {
  margin-left: 0;
}

.str-chat__message.str-chat__message--other .str-chat__message-bubble .str-chat__message-text {
  border-radius: 0px 16px 16px 16px;
  background-color: var(--str-chat__secondary-surface-color);
  display: flex;
  width: fit-content;
}

.str-chat__message-attachment-card {
}

.str-chat__message-attachment--image {
}

.str-chat__message.str-chat__message--me {
  grid-template-areas:
    '. message'
    '. replies'
    '. metadata'
    '. translation-notice';
  .str-chat__message-inner {
    grid-template-areas:
      'options message-bubble'
      'reactions reactions';
  }
  .str-chat__message-bubble {
    background-color: transparent;
  }
}

.str-chat__message {
  .str-chat__message-sender-avatar {
    align-self: flex-start;
  }

  .str-chat__message-simple-name {
    color: var(--system-component-onSurface);
    font-size: var(--reference-fontSize-sm);
    font-weight: 700;
  }
  .str-chat__message-simple-timestamp {
    color: var(--system-component-onSurfaceHigh);
    font-family: var(--reference-fontFamily-primary, 'Hiragino Sans');
    font-size: var(--reference-fontSize-sm, 12px);
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    width: 36px;
  }
  .str-chat__message-bubble {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    flex-direction: column-reverse;
    display: flex;
    background-color: transparent;
  }
}

.str-chat__message-input-inner {
  position: relative;
}

.str-chat__message-textarea-container {
  border-radius: 8px !important;
  border-color: #e3e3e3 !important;
}

.str-chat__file-input-container {
  width: 100% !important;
  height: 100% !important;
  padding: 0.25rem;
}

.str-chat__message-simple-timestamp {
  pointer-events: none;
}

.str-chat__message-textarea-with-emoji-picker {
  cursor: text;
  padding: 0 0 2.5rem 0 !important;
}

.str-chat-react__modal__inner {
  padding: 1rem 0 !important;
}

.str-chat__thread-container {
  max-width: var(--str-chat__container-width);
  justify-content: space-between;
}

.str-chat__thread-container .str-chat__thread-list {
  min-height: 200px;
}

.str-chat__thread .str-chat__main-panel-inner {
  height: 100%;
}

/* https://github.com/u-motion/u-motion-apps/issues/1628 */
.str-chat__thread-header {
  width: 100%;
}

.str-chat-react__modal__inner > div {
  width: 100%;
}

.str-chat__message-list-scroll {
  padding-right: 1px !important;
  padding-left: 1px !important;
  position: relative;
}

.str-chat__attachment-list img {
  border-radius: var(--system-borderRadius-small, 4px);
}

.str-chat__message-attachment--image,
.str-chat__gallery-image {
  background-color: transparent !important;
}

.str-chat__gallery-image {
  width: 140px;
  height: 140px;
}

.str-chat__attachment-list .str-chat__message-attachment--image {
  border-radius: unset;
}

.image-gallery-slide-wrapper {
  min-width: 1000px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e7;
}

.mantine-RichTextEditor-controlsGroup {
  display: flex;
  gap: 0 0.25rem;
}

.mantine-UnstyledButton-root {
  padding: 0.25rem 0.375rem 0 0.375rem;
  border-radius: 4px;
}

.mantine-UnstyledButton-root[data-active='true'] {
  background-color: #e4e4e4;
}

.mantine-RichTextEditor-root {
  width: 100%;
}

.mantine-RichTextEditor-toolbar {
  padding: 0.25rem 0.75rem;
  display: flex;
  position: sticky;
  top: 0;
  gap: 0 1rem;
  background-color: #f4f4f4;
  border-radius: 8px 8px 0 0;
  z-index: 2;
}

.mantine-RichTextEditor-content {
  border-top: 1px solid #f4f4f4;
  padding: 0.75rem 1rem 0 1rem;
  width: 100%;
  line-height: 1.5;
}

.mantine-RichTextEditor-content p,
.mantine-RichTextEditor-content li {
  margin-bottom: 0 !important;
}

.mantine-RichTextEditor-content ul,
.mantine-RichTextEditor-content ol {
  padding-left: 1.5rem !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem !important;
}

.mantine-RichTextEditor-content blockquote {
  background-color: transparent;
  border-left: 4px solid #e4e4e4;
  border-radius: 1px 1px 1px 1px;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
}

.mantine-RichTextEditor-content pre {
  background-color: #f4f4f4;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mantine-RichTextEditor-content code {
  font-size: 0.875rem;
  padding: 0;
}

.ProseMirror {
  max-height: calc(50vh - 6rem);
  overflow-y: auto;
}

.ProseMirror-focused {
  outline: none;
}

.rta__autocomplete {
  padding: 0;
  z-index: 20;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.str-chat__thread-container.str-chat__thread {
  overflow: hidden;
}

.str-chat__message-input
  .str-chat__message-input-inner
  .str-chat__message-textarea-container
  .str-chat__message-textarea-with-emoji-picker
  .str-chat__message-textarea {
  min-height: 0;
  max-height: 300px;
}

.str-chat__message-input
  .str-chat__message-input-inner
  .str-chat__message-textarea-container
  .str-chat__message-textarea-with-emoji-picker
  .str-chat__message-textarea
  > div
  > div {
  height: 100%;
}

.str-chat__attachment-list .str-chat__message-attachment--image:not(.str-chat__message-attachment--card) > img {
  height: unset;
}

/* スレッドで同ユーザーが連投するとメッセージのタイムスタンプが表示されなくなるのを防いでいる */
.str-chat__li--middle .str-chat__message .str-chat__message-metadata,
.str-chat__li--top .str-chat__message .str-chat__message-metadata {
  display: block;
}

.str-chat__li--top .str-chat__message--me .str-chat__message-bubble,
.str-chat__li--single .str-chat__message--me .str-chat__message-bubble {
  background: transparent;
  align-items: flex-end;
}

.str-chat__li--top .str-chat__message--me .str-chat__message-bubble,
.str-chat__li--single .str-chat__message--me .str-chat__message-bubble {
  border-end-end-radius: 16px;
}

.str-chat__message .str-chat__message-replies-count-button-wrapper button {
  color: var(--str-chat__primary-surface-color);
  font-size: var(--reference-fontSize-sm);
  font-weight: 700;
}

.str-chat__message.str-chat__message--me .str-chat__message-bubble .str-chat__message-text {
  color: var(--onPrimary);
  background: var(--bubblePrimary);
  font-size: var(--reference-fontSize-md);
  font-weight: 400;
  max-width: 450px;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--str-chat__border-radius-md);
  border-bottom-right-radius: var(--str-chat__border-radius-md);
  border-top-left-radius: var(--str-chat__border-radius-md);
}

/* マルチカラムの時にメッセージエリアにマウスポインタを合わせると横スクロールが無効になるのを防いでいる */
/* https://github.com/u-motion/u-motion-apps/issues/2422 */
.str-chat__list {
  overscroll-behavior-y: none;
  overscroll-behavior-x: auto;
}

li:has(#custom-header) {
  position: sticky;
  top: 0;
  z-index: 100;
}

/* Message Options */
button.str-chat__message-actions-list-item.str-chat__message-actions-list-item-button {
  display: none;
}

.custom-menu-action > button.str-chat__message-actions-list-item.str-chat__message-actions-list-item-button {
  min-width: 200px;
  padding: 0;
  display: block;
}

.str-chat__ul:not(.str-chat__message-options-in-bubble)
  .str-chat__li:hover:not(:has(.str-chat__reaction-list:hover, .str-chat__modal--open))
  .str-chat__message-options,
.str-chat__ul:not(.str-chat__message-options-in-bubble)
  .str-chat__li:focus-within:not(:has(.str-chat__reaction-list:focus-within, .str-chat__modal--open))
  .str-chat__message-options,
.str-chat__virtual-list:not(.str-chat__message-options-in-bubble)
  .str-chat__li:hover:not(:has(.str-chat__reaction-list:hover, .str-chat__modal--open))
  .str-chat__message-options,
.str-chat__virtual-list:not(.str-chat__message-options-in-bubble)
  .str-chat__li:focus-within:not(:has(.str-chat__reaction-list:focus-within, .str-chat__modal--open))
  .str-chat__message-options {
  align-items: flex-end;
}

span.str-chat__mesage-simple-edited {
  display: none;
}

.str-chat__message .str-chat__message-inner,
.str-chat__quoted-message-preview .str-chat__message-inner {
  column-gap: 0;
}

.custom-checkbox-before label div:nth-child(2) {
  border-color: #737373;
  border-radius: 2px;
}
.custom-checkbox-before label div:nth-child(2):hover {
  border-color: #737373;
}

.custom-checkbox label {
  background-color: #ff811a;
  border-radius: 2px;
}
.custom-checkbox label div:nth-child(2) {
  border-width: 0;
}

.custom-checkbox label div:nth-child(2) svg {
  color: white;
}

.str-chat__message-actions-box.str-chat__message-actions-box--open {
  z-index: 100;
}

/* Date-separator */
.str-chat__date-separator-line {
  display: none;
}

li:has(.str-chat__date-separator) {
  position: sticky;
  top: 64px;
  z-index: 10;
}

.str-chat__date-separator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.str-chat__date-separator-date {
  display: flex;
  padding: var(--system-spacing-xs, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--system-spacing-sm, 12px);
  border-radius: var(--system-borderRadius-full, 9999px);
  background: var(--system-component-surface, #f1f1f1);
  /* https://github.com/u-motion/u-motion-apps/issues/3092#issuecomment-2609656928 */
  width: 150px;
}

.str-chat__list .str-chat__parent-message-li .str-chat__thread-start {
  font-family: 'Hiragino Sans';
  font-size: var(--reference-fontSize-md);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  padding-top: var(--system-spacing-md, 16px);
}
.str-chat__attachment-list .str-chat__message-attachment--gallery .str-chat__gallery {
  display: flex;
  flex-wrap: wrap;
  gap: var(--system-spacing-2xs, 4px);
  height: unset;
  border-radius: unset;
  max-width: calc(140px * 3 + 2 * 4px);
  margin-top: var(--system-spacing-xs, 8px);
}
.str-chat__message--me .str-chat__attachment-list .str-chat__message-attachment--gallery .str-chat__gallery {
  justify-content: flex-end;
}
.str-chat__attachment-list .str-chat__message-attachment-card--header {
  height: unset;
  border-radius: var(--system-borderRadius-small, 4px);
}

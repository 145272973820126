div[data-classname='message_parent'] ul {
  margin-top: 0;
  margin-bottom: 0;
}
div[data-classname='message_parent'] p {
  margin-top: 2px;
  margin-bottom: 2px;
}
div[data-classname='message_parent'] ul > br,
ol > br {
  display: none;
}
